import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import React, { useEffect, Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";
import "./App.scss";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import frCALocale from "date-fns/locale/fr-CA";
import Login from "./components/auth/Login";
import TvDisplay from "./components/Dashboard/TvDisplay";
import TvDisplay2 from "./components/Dashboard/TvDisplay2";
import TvDisplay3 from "./components/Dashboard/TvDisplay3";
import TvDisplay4 from "./components/Dashboard/TvDisplay4";
import PrivateRoute from "./components/private-route/PrivateRoute";

import "antd/dist/antd.css";

import axios from "axios";

import "devextreme/dist/css/dx.light.css";

 ///axios.defaults.baseURL = 'http://localhost:5000/'; 
 axios.defaults.baseURL = 'http://45.76.47.42/';  //EN LIGNE

const DefaultLayout = React.lazy(() =>
  import("./components/layout/DefaultLayout")
);
// const TvDisplay = React.lazy(() =>
//   import("./components/Dashboard/TvDisplay")
// );

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

const App = () => {
  useEffect(() => {
    function handleAdd(e) {
      if (e.key === "jwtToken" && e.oldValue && !e.newValue) {
        store.dispatch(logoutUser());
      }
    }
    window.addEventListener("storage", handleAdd);
    return () => {
      window.removeEventListener("storage", handleAdd, false);
    };
  }, []);

  return (
  
    <Provider store={store}>
      <Router>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frCALocale}>
          <React.Suspense fallback={loading()}>
            {window.location.pathname === "/tvDisplay" ? (
              <Route exact path="/tvDisplay" component={TvDisplay} />
            )
            :
            window.location.pathname === "/tvDisplay2" ? (
              <Route exact path="/tvDisplay2" component={TvDisplay2} />
            )
            :
            window.location.pathname === "/tvDisplay3" ? (
              <Route exact path="/tvDisplay3" component={TvDisplay3} />
            )
            
            :
            
            window.location.pathname === "/TvDisplay4" ? 
            
            (
              <Route exact path="/TvDisplay4" component={TvDisplay4} />
            )
            :
            (
              <React.Fragment>
                <Route exact path="/login" component={Login} />
                <Switch>
                  <PrivateRoute
                    name="Home"
                    path="/"
                    component={DefaultLayout}
                  />
                </Switch>
              </React.Fragment>
            )}
          </React.Suspense>
        </MuiPickersUtilsProvider>
      </Router>
    </Provider>
  );
};
export default App;
